import React, { Component } from 'react';
import {getParam, urlInterfase, rootSite} from './conn.js';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  appBar: {
    flexGrow: 1,
  }
});

class App extends Component {
  state = {
    logo: null, 
    consulta: {records : []},
    empresa_id: '',
    rfc: '',
    empleado_id: ''
  }

  componentDidMount = () => {
    this.getEmpresa();
  }

  getEmpresa = () => {
    const app = this;
    const data = new FormData();
    data.append("empresa", getParam("x"));
    data.append("rfc", getParam("z"));
    data.append("opReq", "GetEmpresa");    

    fetch(urlInterfase,
    {
        method: "POST",
        body: data,
        credentials: 'include'
    })
    .then(function(res){ 
      return res.json(); 
    })
    .then(function(data){ 
      app.setState(data);      
      console.log(data);
      app.loadLogo();
      app.loadData();
    });    
  }

  loadLogo = () => {
    const app = this;
    const data = new FormData();
    data.append("empresa_id", this.state.empresa_id);
    data.append("opReq", "GetLogo");    

    fetch(urlInterfase,
    {
        method: "POST",
        body: data,
        credentials: 'include'
    })
    .then(function(res){ 
      return res.json(); 
    })
    .then(function(data){ 
      app.setState({logo: data.logo});      
    });
  }  

  loadData = () => {
    const app = this;
    const data = new FormData();
    data.append("empresa_id", this.state.empresa_id);
    data.append("receptor_rfc", this.state.rfc);
    data.append("estatus", "R");
    data.append("opReq", "Search");    

    fetch(urlInterfase,
    {
        method: "POST",
        body: data,
        credentials: 'include'
    })
    .then(function(res){ 
      return res.json(); 
    })
    .then(function(data){ 
      app.setState({consulta: data});      
    });
  }

  downLoad = (rowData) => {
    var parameters = "opReq=DescargarComprobante" +
                     "&serie=" + rowData.serie +
                     "&folio=" + rowData.folio +
                     "&empresa_id=" + this.state.empresa_id;

    document.location = urlInterfase + "?" + parameters;
  }

  printPDF = (rowData) => {
    var parameters = "opReq=PrintPdf" +
                     "&serie=" + rowData.serie +
                     "&folio=" + rowData.folio +
                     "&empresa_id=" + this.state.empresa_id;

    window.open(urlInterfase + "?" + parameters, "_blank", "resizable=yes, scrollbars=yes, titlebar=yes, width=800, height=600, top=10, left=10");
  }

  render() {
    return (
      <div className="App">
        <div height="50px">
        {this.state.logo != null &&
            <img src={`data:image/jpeg;base64,${this.state.logo}`} height="50px" width="100px" />
        }
        </div>
        
        <MaterialTable
          columns={[
            { title: 'Emisión', field: 'fecha', searchable: true },
            { title: 'Serie - Folio', searchable: true, render: rowData => rowData.serie + "-" + rowData.folio },
            { title: 'Tipo', render: rowData => "Nómina" },
            { title: 'Estado', render: rowData => "Emitido" }
          ]}
          data={this.state.consulta.records}
          title="Mis Comprobantes"
          actions={[
            rowData => ({
              icon: 'get_app',
              tooltip: 'Descarga',
              disabled: rowData.birthYear >= 2000,
              onClick: (event, rowData) => {
                this.downLoad(rowData);
                //alert('You clicked user ' + rowData.name)
              },
            }),
            {
              icon: 'open_in_browser',
              tooltip: 'Vista',
              onClick: (event, rowData) => {
                this.printPDF(rowData);
              },
              iconProps: {
                style: {
                  fontSize: 30,
                  color: 'green',
                },
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
          }}

          localization={{
            header: {
              actions: ' '
            },

            body: {
              emptyDataSourceMessage: 'No existe información',
              filterRow: {
                filterTooltip: 'Filtro'
              }
            }
          }}
        />

        <Button variant="contained" className={styles.button} onClick={this.loadData}>
          Consultar
        </Button>        
      </div>
    );
  }
}

export default App;
